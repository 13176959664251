import React from "react"

import InnerLayout from "../components/InnerLayout"
import InnerWrapper from "../components/InnerWrapper"
import SEO from "../components/seo"
import Hero from "../components/frontpage/Hero"
import Cases from "../components/projects/Cases"
import Newsletter from "../components/Newsletter"

const IndexPage = () => (
  <InnerLayout noTopPadding>
    <SEO title="Home"/>
    <Hero/>
    <InnerWrapper>
      <Cases number={3}/>
    </InnerWrapper>
    <Newsletter />
  </InnerLayout>
)

export default IndexPage
