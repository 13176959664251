import React, { Component } from 'react';
import classNames from 'classnames';

import reelShort from '../../images/reel/hhb-reel-preview.mp4';
// import reelFull from '../../images/reel/hhb-reel-full.mp4';
import videoPoster from '../../images/reel/video-poster.jpg';
import videoPosterFull from '../../images/reel/video-poster-full.jpg';

class Hero extends Component {

  state = { 
    open: false,
    ready: true,
  };

  video = React.createRef();
  wrapper = null;
  hero = null;
  reelVimeoUrl = 'https://player.vimeo.com/external/405400045.hd.mp4?s=dc4a57755727c58625fe17492b2aabb9931a0677&profile_id=175';

  toggleModal() {
    if(this.state.ready && this.wrapper && this.hero) {
      this.setState(prevState => ({
        open: !prevState.open
      }));
      if(this.state.open) {
        this.video.current.pause();
        this.wrapper.style.zIndex = 1;
        this.hero.removeAttribute('style');
      } else {
        this.hero.style.background = 'transparent';
        this.wrapper.style.zIndex = 9;
        setTimeout(() => { this.video.current.play(); }, 400);
      }
    }
  }

  render() {
    const { open, ready } = this.state;
    const modalClass = classNames('Hero__modal', {
      'Hero__modal--open': open,
    });
    if (typeof window !== 'undefined') {
      this.wrapper = document.querySelector('.tl-wrapper');
      this.hero = document.querySelector('.Hero');
    }
    return (
      <section className="Hero bg-lightgray outer-padding-x">
        <div className="Hero__inner">
          <div className="Hero__intro">
            <p className="color-bluegray fadein">Hi, my name is</p>
            <h1 className="fadein">Henrik H. Boelsmand</h1>
            <h2 className="fadein">Freelance web developer based in Copenhagen.</h2>
            <h3 className="fadein">I work with <span className="color-blue">agencies</span> and <span className="color-blue">startups</span> to create outstanding <span className="color-blue">user experiences</span> through <span className="color-blue">websites</span> and <span className="color-blue">applications</span>.</h3>
            { ready ? (
              <button className="Hero__videobtn fadein" onClick={ () => this.toggleModal() }>
                <div>
                  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 124.512 124.512">
                    <path d="M113.956,57.006l-97.4-56.2c-4-2.3-9,0.6-9,5.2v112.5c0,4.6,5,7.5,9,5.2l97.4-56.2
                    C117.956,65.105,117.956,59.306,113.956,57.006z"/>
                  </svg>
                </div>
                <span>Watch UI reel</span>
              </button>
              ) : null
            }
          </div>
          <div className="Hero__video__wrapper fadein">
            { ready ? (
              <button onClick={ () => this.toggleModal() }>
                <figure className="Hero__video">
                  <video autoPlay muted playsInline loop preload="auto" src={reelShort} poster={videoPoster}></video>
                  <div className="Hero__video__play">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 124.512 124.512">
                      <path d="M113.956,57.006l-97.4-56.2c-4-2.3-9,0.6-9,5.2v112.5c0,4.6,5,7.5,9,5.2l97.4-56.2
                      C117.956,65.105,117.956,59.306,113.956,57.006z"/>
                    </svg>
                  </div>
                </figure>
              </button>
              ) : (
                <button onClick={ () => this.toggleModal() }>
                  <figure className="Hero__video">
                    <video autoPlay muted playsInline loop preload="auto" src={reelShort} poster={videoPoster}></video>
                    <span className="Hero__video--comingsoon">UI Reel coming soon</span>
                  </figure>
                </button>
              )
            }
          </div>
        </div>
        <div className={modalClass}>
          <div className="Hero__modal__inner">
            <video ref={this.video} className="Hero__modal__video" controls preload="auto" src={this.reelVimeoUrl} poster={videoPosterFull}></video>
          </div>
          <div className="Hero__modal__backdrop" onClick={ () => this.toggleModal() }></div>
        </div>
      </section>
    )
  }

}

export default Hero